function ClickMore() {
  return (
    <div className="headBox ClickMore">
      <div className="row m-0">
        <div className="col-md-6 order-md-first p-0">
          <img src="/images/partner/headBox3.png" className="w-100" alt="" />
        </div>
        <div className="col-md-6 order-md-last p-0">
          <div className="secondPars col-md-10 ml-auto">
            <h3 className="headText">More Click and Share</h3>
            <p className="bodyText">Best Platform to earn lifetime</p>
            <p className="smallBodyText">
              Nam id mi sollicitudin magna dictum pellentesque non ac purus. Etiam leo nibh, finibus
              eget porttitor sit amet, consequat ac lacus. Quisque ornare, arcu quis lacinia
              hendrerit, quam metus hendrerit mauris, sit amet luctus turpis sem nec diam. Aliquam
              feugiat nibh tortor, a varius leo tristique nec
            </p>

            <button className="btn  btn-danger text-white w-75 mt-2">See Plans</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ClickMore;
