function Plan() {
  return (
    <div>
      <div className="row m-0 ">
        {/* plan1 start */}
        <div
          className="col-lg-4 px-0 px-lg-3"
          data-aos="zoom-in"
          data-aos-duration="1000"
          // data-aos-delay="1000"
        >
          <div className="img-logo-plan">
            <img src="images/Badge 1.png" alt="" />
          </div>

          {/* plan1 body */}
          <div className="plan plan1">
            <img src="images/SMART.png" alt="" />
            <h1>
              <small>€</small>
              <span>00.00</span>
            </h1>
            {/* plan1 details */}
            <div className="plan1-details plan-details">
              <h6 className="title">FREE ACCESS</h6>
              <ul className="body p-1 px-3 text-left">
                <li>
                  <h6>
                    <img src="images/Accessee 1.png" alt="" />
                    <span className="text-white ml-3">Limited Access</span>
                  </h6>
                  <h6>
                    <img src="images/TO-DO 1.png" alt="" />
                    <span className="text-white ml-3">To-Do</span>
                    <ul className="plan-list">
                      <li>SEO</li>
                    </ul>
                  </h6>
                  <h6>
                    <img src="images/Reward 1.png" alt="" />
                    <span className="text-white ml-3">Reward Programm</span>
                    <ul className="plan-list">
                      <li>Product Smart Points</li>
                    </ul>
                  </h6>
                  <h6>
                    <img src="images/Star 1.png" alt="" />
                    <span className="text-white ml-3">Ad Star Programm</span>
                    <ul className="plan-list">
                      <li>Daily Ad Star Programm</li>
                    </ul>
                  </h6>
                  <h6>
                    <img src="images/Group 2194.png" alt="" />
                    <span className="text-white ml-3">Multiplier</span>
                    <ul className="plan-list">
                      <li>
                        <img src="images/Group 2186.png" alt="" />
                      </li>
                    </ul>
                  </h6>
                </li>
              </ul>
            </div>
            <button className="btn plan-btn shadow">Get Started</button>
          </div>
        </div>
        {/* plan 1 end */}

        {/* plan 2 start */}
        <div
          className="col-lg-4 px-0 px-lg-3"
          data-aos="zoom-in"
          data-aos-duration="1000"
          // data-aos-delay="1000"
        >
          <div className="img-logo-plan">
            <img src="images/Pro 1.png" alt="" />
          </div>
          {/* plan2 body */}
          <div className="closebadge">
            NOW <h3 className="d-inline">€200</h3> Lifttime
          </div>
          <div className="exclusiv">
            <span className="d-none d-lg-inline">exclusiv for</span>
            <img src="images/image 1.png" className="planImg" alt="" />
          </div>
          <div className="plan plan2">
            <img src="images/PRO.png" className="planImg" alt="" />
            <h1>
              <small>€</small>
              <span>
                <strike>24.90</strike>
              </span>
            </h1>

            {/* plan2 details */}
            <div className="plan2-details plan-details">
              <h6 className="title">VIP EXCLUSIV </h6>
              <ul className="body p-1 px-3 text-left">
                <li>
                  <h6>
                    <img src="images/Accessee 1.png" alt="" />
                    <span className="text-white ml-3">Sign up for any product</span>
                  </h6>
                  <h6>
                    <img src="images/TO-DO 1.png" alt="" />
                    <span className="text-white ml-3">To-Do</span>
                    <br />
                    <ul className="plan-list planlist-2">
                      <li>SEO</li>
                      <li className="ml-4">ORM</li>
                    </ul>
                    <small className="text-white d-block ml-5 small-txt-plan">
                      Web Pages, News Page, Sales Pages, Niche Sites, Social Media, Forum, Channel,
                      Keyword Search, Shop
                    </small>
                  </h6>
                  <h6>
                    <img src="images/Reward 1.png" alt="" />
                    <span className="text-white ml-3">Reward Programm</span>
                    <ul className="plan-list">
                      <li>Product Smart Points</li>
                      <li>Company Exkulsive Points</li>
                      <li>Clisha Super Points</li>
                    </ul>
                  </h6>
                  <h6>
                    <img src="images/Star 1.png" alt="" />
                    <span className="text-white ml-3">Ad Star Programm</span>
                    <ul className="plan-list">
                      <li>Daily Ad Star Programm</li>
                      <li>Weekly Ad Star Programm</li>
                      <li>Monthly Ad Star Programm</li>
                    </ul>
                  </h6>
                  <h6>
                    <img src="images/Extra Ranking 1.png" alt="" />
                    <span className="text-white ml-3">Raking System</span>
                    <ul className="plan-list">
                      <li>Slot Bonus</li>
                      <li>Spin Weel Bonus</li>
                      <li>Tombola Bonus</li>
                    </ul>
                  </h6>
                  <h6>
                    <img src="images/Affiliate 1.png" alt="" />
                    <span className="text-white ml-3">Empfehlungslink</span>
                    <ul className="plan-list">
                      <li>Life-Time Promo Affiliate-Link</li>
                      <li>Direkte Provision</li>
                      <li>bei Erfolg Multiplikator 6</li>
                    </ul>
                  </h6>
                  <h6>
                    <img src="images/Group 2194.png" alt="" />
                    <span className="text-white ml-3">Multiplier</span>
                    <ul className="plan-list">
                      <li>
                        <img src="images/Group 2195.png" alt="" />
                      </li>
                    </ul>
                  </h6>
                </li>
              </ul>
            </div>
            <div className="downtxt">
              <img src="images/image 2.png" alt="" />
              <span>EXCLUSIV</span>
              <img src="images/Group 2207.png" alt="" />
            </div>
            <button className="btn plan-btn shadow">Get Started</button>
            <br />
            <br />
          </div>
        </div>
        {/* plan 2 end */}

        {/* plan3 start */}
        <div
          className="col-lg-4 px-0 px-lg-3"
          data-aos="zoom-in"
          data-aos-duration="1000"
          // data-aos-delay="1000"
        >
          <div className="img-logo-plan">
            <img src="images/Pamc 1.png" alt="" />
          </div>

          {/* plan3 body */}
          <div className="closebadge">
            NOW <h3 className="d-inline">€150</h3> Lifttime
          </div>
          <div className="exclusiv">
            <span className="d-none d-lg-inline">exclusiv for</span>
            <img src="images/Pro 2.png" className="planImg" alt="" />
          </div>
          <div className="plan plan3">
            <img src="images/PAM.png" className="planImg" alt="" />
            <h1>
              <small>€</small>
              <strike>19.90</strike>
            </h1>
            {/* plan3 details */}
            <div className="plan3-details plan-details">
              <h6 className="title">PRO EXCLUSIV </h6>
              <ul className="body p-1 px-3 text-left">
                <li>
                  <h6>
                    <img src="images/Accessee 1.png" alt="" />
                    <span className="text-white ml-3">Sign up for any product</span>
                  </h6>
                  <h6>
                    <img src="images/TO-DO 1.png" alt="" />
                    <span className="text-white ml-3">To-Do</span>
                    <br />
                    <ul className="plan-list planlist-3">
                      <li className="mb-0">SEO</li>
                      <li className="mb-0">Social Media</li>
                    </ul>
                    <ul className="plan-list planlist-3 mt-0 pt-0">
                      <li className="mt-0">ORM</li>
                      <li className="mt-0">Spezial To-do’s</li>
                    </ul>
                    <small className="text-white d-block ml-5 small-txt-plan">
                      Web Pages, News Page, Sales Pages, Niche Sites, Social Media, Forum, Channel,
                      Keyword Search, Shop
                    </small>
                  </h6>
                  <h6>
                    <img src="images/Reward 1.png" alt="" />
                    <span className="text-white ml-3">Reward Programm</span>
                    <ul className="plan-list">
                      <li>Product Smart Points</li>
                      <li>Company Exkulsive Points</li>
                      <li>Clisha Super Points</li>
                    </ul>
                  </h6>
                  <h6>
                    <img src="images/Star 1.png" alt="" />
                    <span className="text-white ml-3">Ad Star Programm</span>
                    <ul className="plan-list">
                      <li>Daily Ad Star Programm</li>
                      <li>Weekly Ad Star Programm</li>
                      <li>Monthly Ad Star Programm</li>
                    </ul>
                  </h6>
                  <h6>
                    <img src="images/Extra Ranking 1.png" alt="" />
                    <span className="text-white ml-3">Raking System</span>
                    <ul className="plan-list">
                      <li>Slot Bonus</li>
                      <li>Spin Weel Bonus</li>
                      <li>Tombola Bonus</li>
                    </ul>
                  </h6>
                  <h6>
                    <img src="images/Affiliate 1.png" alt="" />
                    <span className="text-white ml-3">Empfehlungslink</span>
                    <ul className="plan-list">
                      <li>Life-Time Promo Affiliate-Link</li>
                      <li>Direkte Provision</li>
                      <li>bei Erfolg Multiplikator 6</li>
                    </ul>
                  </h6>
                  <h6>
                    <img src="images/Group 2194.png" alt="" />
                    <span className="text-white ml-3">Multiplier</span>
                    <ul className="plan-list">
                      <li>
                        <img src="images/Group 2204.png" alt="" />
                      </li>
                    </ul>
                  </h6>
                </li>
              </ul>
            </div>
            <div className="downtxt">
              <img src="images/Pro 3 (1).png" alt="" />
              <span>EXCLUSIV</span>
              <img src="images/Group 2207.png" alt="" />
            </div>
            <button className="btn plan-btn shadow">Get Started</button>
            <br />
            <br />
          </div>
        </div>
        {/* plan 3 end */}
      </div>
    </div>
  );
}

export default Plan;
