function Loading() {
  return (
    <div className="loading">
      <div className="load-content">
        <i className="fa fa-spinner fa-spin" />
      </div>
    </div>
  );
}

export default Loading;
