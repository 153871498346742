function AcceptedPartners() {
  return (
    <div className="AcceptedPartners">
      <div className="row m-0">
        <div className="col-md-6">
          <h1>This is Accepted !</h1>
          <h3>Our Partner Alaways Happy</h3>
          <p>
            Nam id mi sollicitudin magna dictum pellentesque non ac purus. Etiam leo nibh, finibus
            eget porttitor sit amet, consequat ac lacus. Quisque ornare, arcu quis lacinia
            hendrerit, quam metus hendrerit mauris, sit amet luctus turpis sem nec diam. Aliquam
            feugiat nibh tortor, a varius leo tristique nec
          </p>
        </div>
        <div className="col-md-6">
          <img src="/images/partner/imgbg1.png" className="w-100" alt="" />
        </div>
      </div>
    </div>
  );
}

export default AcceptedPartners;
