export const nav = [
  {
    name: 'Home',
    url: '/',
  },
  {
    name: 'Become Partner',
    url: '/#become-partner', // become-partner
  },
  {
    name: 'All Products',
    url: '/#all-products', // all-products
  },

  {
    name: 'Membership',
    url: '/#membership', // membership
  },
  {
    name: 'Clisha User',
    url: '/#clisha-users', // clisha-users
  },
];

export const partnersImg = [
  {
    img: '/images/partner/img1.png',
    name: 'Mr. Florian Engel',
    position: 'Cheif Operation Officer',
    company: 'Think Investment Group',
  },
  {
    img: '/images/partner/img2.png',
    name: 'Mr. Florian Engel',
    position: 'Cheif Operation Officer',
    company: 'Think Investment Group',
  },
  {
    img: '/images/partner/img3.png',
    name: 'Mr. Florian Engel',
    position: 'Cheif Operation Officer',
    company: 'Think Investment Group',
  },
  {
    img: '/images/partner/img4.png',
    name: 'Mr. Florian Engel',
    position: 'Cheif Operation Officer',
    company: 'Think Investment Group',
  },
  {
    img: '/images/partner/img5.png',
    name: 'Mr. Florian Engel',
    position: 'Cheif Operation Officer',
    company: 'Think Investment Group',
  },
  {
    img: '/images/partner/img6.png',
    name: 'Mr. Florian Engel',
    position: 'Cheif Operation Officer',
    company: 'Think Investment Group',
  },
];
