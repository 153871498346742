function Footer() {
  return (
    <div className="text-light text-center dashboardFooter d-none bg-dark m-0  p-2">
      <small>
        <b>@copyright!</b> all rights reserved to clisha.click ! to copy any element or commercial
        use highly prohibited against law
      </small>
    </div>
  );
}

export default Footer;
